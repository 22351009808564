import React, { useContext } from "react"
import { StyledFooter } from "../Components/styled-components/index.styledComponents"
import { useStaticQuery, graphql } from "gatsby"
import { StoreContext } from "../store/StoreProvider"

const FooterSection = () => {
  const { language } = useContext(StoreContext)

  const content = useStaticQuery(graphql`
    query footerSectionQuery {
      allDatoCmsContent {
        nodes {
          contentLang
          footerContent
        }
      }
    }
  `).allDatoCmsContent.nodes.find(
    el =>
      String(el.contentLang).toLowerCase() === String(language).toLowerCase()
  )
  const { footerContent } = content

  return (
    <StyledFooter>
      <p>{footerContent}</p>
    </StyledFooter>
  )
}

export default FooterSection
