import styled from "styled-components"
import { device } from "../../utils/device"

export const StyledActivityCard = styled.article`
  width: 100%;
  margin: 3vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.laptop} {
    max-width: 30%;
    margin: 5vh 1vw;
  }

  img {
    display: block;
    height: 250px;
    margin: 3vh 0;
    @media ${device.tablet} {
      max-width: 100%;
    }
  }
  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    text-align: center;
    font-size: ${props => props.theme.fontSize.l};
    color: ${props => props.theme.colors.gray};
    font-style: normal;
    background: linear-gradient(
      #d8e8f9 0%,
      #ffffff 20%,
      #d8e8f9 60%,
      #ffffff 80%,
      #d8e8f9 100%
    );
    border-radius: 5px;
    box-shadow: 0px 0px 25px #2f243a;
    height: 300px;
    @media ${device.tablet} {
      padding: 20px;
      max-width: 50vh;
      height: 400px;
    }
    h3 {
      color: ${props => props.theme.colors.gray};
      font-style: normal;
      font-weight: bold;
      @media ${device.tablet} {
        font-size: ${props => props.theme.fontSize.l};
      }
    }
    p {
      padding: 0 20px;
      line-height: 32px;
      @media ${device.laptop} {
        font-size: ${props => props.theme.fontSize.l};
      }
    }
  }
`
