import React from "react"
import { Link } from "gatsby"
import { StyledButton } from "./styled-components/Button.style"
import PropTypes from "prop-types"

const Button = ({
  buttonText,
  loadMore,
  menu,
  click,
  link,
  extLink,
  white,
  className,
  blank,
}) => {
  return (
    <StyledButton
      menu={menu}
      className={className}
      loadMore={loadMore}
      white={white}
      onClick={click}
    >
      {link && <Link to={link ? link : ""}>{buttonText}</Link>}
      {extLink && (
        <a href={extLink} target={blank && "_blank"}>
          {buttonText}
        </a>
      )}
      {!link && !extLink && buttonText}
    </StyledButton>
  )
}

export default Button

Button.propTypes = {
  button: PropTypes.string,
  loadMore: PropTypes.bool,
  menu: PropTypes.bool,
  click: PropTypes.func,
  link: PropTypes.string,
  white: PropTypes.bool,
}
