import hi2 from "../assets/svg/popr/hi2.svg"
import bans from "../assets/svg/popr/bans.svg"
import calm from "../assets/svg/popr/calm.svg"
import guitarist from "../assets/svg/popr/guitarist.svg"
import gym from "../assets/svg/popr/gym.svg"
import hej1 from "../assets/svg/popr/hej1.svg"
import linkedin from "../assets/svg/linkedin.svg"
import messenger from "../assets/svg/messenger.svg"
import github from "../assets/svg/github.svg"
import plFlag from "../assets/svg/flags/pl-pl.svg"
import enFlag from "../assets/svg/flags/en-gb.svg"
import logo from "../assets/svg/logo/Bsroka-logos_black.png"

export const images = {
  hi2,
  bans,
  calm,
  guitarist,
  gym,
  hej1,
  linkedin,
  messenger,
  github,
  plFlag,
  enFlag,
  logo,
}
