import styled from "styled-components"
import { device } from "../../utils/device"

export const StyledNewsCard = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 440px;
  max-width: 350px;
  background-color: ${props => props.theme.colors.white};
  margin: 15px 10px;
  padding: 0 0 40px;
  border-radius: 5px;
  box-shadow: ${props => props.theme.shadows.postShadow};
  cursor: pointer;
  transform-style: preserve-3d;
  overflow: hidden;
  @media ${device.laptopL} {
    margin: 35px 30px;
  }
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &:nth-of-type(1) {
    background-color: ${props => props.theme.colors.lightcolor};
    position: relative;
    &:before {
      content: "NEW";
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      z-index: 5555;
      color: black;
      font-size: 25px;
      text-align: center;
      font-family: ${props => props.theme.fontFamily.header};
      background: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.white};
      box-shadow: 0px 0px 25px ${props => props.theme.colors.white};
      transform: rotate(317deg) translate(-75px, -25px);
      transform-origin: center top;
    }
  }
`
