import styled, { css } from "styled-components"
import { device } from "../../utils/device"

export const StyledContactIcons = styled.article`
  align-self: center;
  justify-self: center;
  display: flex;

  img {
    margin: 10px;
    width: 7vw;
    opacity: 0.8;
    transition: 0.2s;
    @media ${device.tablet} {
      width: 5vw;
    }
    @media ${device.laptop} {
      width: 3vw;
    }
  }

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  ${({ fixedFloater }) =>
    fixedFloater &&
    css`
      img {
        max-width: 25px;
      }
    `}
`
