import React, { useContext } from "react"
import Menu from "../Components/Menu"
import Hamburger from "../Components/Hamburger"
import { StoreContext } from "../store/StoreProvider"
import { mobileMenuAnimation } from "../animations/menuAnimation/mobileMenuAnimation"
import { images } from "../utils/images"
import { StyledImage } from "../Components/styled-components/Image.style"
import { Link } from "gatsby"
import { preloaderAnimation } from "../animations/preloader/preloader"

const Navigation = () => {
  const { isMenuOpen, setIsMenuOpen, setLanguage, language } =
    useContext(StoreContext)

  const hamburgerButtonHandler = () => {
    setIsMenuOpen(!isMenuOpen)
    mobileMenuAnimation(!isMenuOpen)
  }
  const languageButtonHandler = async e => {
    preloaderAnimation()
    await setLanguage(e)
    await localStorage.setItem("language", e)
    location.reload()
  }
  const languages = [
    {
      name: "EN",
      src: images.enFlag,
    },
    {
      name: "PL",
      src: images.plFlag,
    },
  ]

  const flags = languages.map(el => (
    <figure
      key={el.name}
      className={`navigation-aside-submenu__image-container ${
        language === el.name ? "language-active" : null
      }`}
    >
      <StyledImage
        src={el.src}
        onClick={() => {
          languageButtonHandler(el.name)
        }}
        style={{
          cursor: "pointer",
          transition: "0.3s",
          margin: "5px",
          maxHeight: "40px",
        }}
      />
    </figure>
  ))

  return (
    <header className="navigation">
      <section className="navigation-container">
        <article className="navigation-aside-submenu">
          <figure className="navigation-aside-submenu__image-container">
            <Link to="/" className="navigation-aside-submenu__image-container">
              <StyledImage src={images.logo} style={{ maxHeight: "75px" }} />
            </Link>
          </figure>
          {flags}
        </article>
        <Hamburger isVisible={isMenuOpen} click={hamburgerButtonHandler} />
        <Menu isVisible={isMenuOpen} />
      </section>
    </header>
  )
}
export default Navigation
