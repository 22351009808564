import styled, { css } from "styled-components"
import { device } from "../../utils/device"

export const StyledButton = styled.button`
  display: block;
  position: relative;
  max-width: 250px;
  margin: 0 8px;
  height: 51px;
  font-size: ${props => props.theme.fontSize.l};
  line-height: 24px;
  font-family: ${props => props.theme.fontFamily.buttons};
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  border: 3px solid black;
  box-shadow: ${props => props.theme.shadows.buttonShadow};
  cursor: pointer;
  opacity: 1;
  transition: 0.2s;
  &:before {
    content: "";
    position: absolute;
    width: 3px;
    height: calc(100% + 8px);
    top: -2px;
    right: -7px;
    background: black;
    transition: 0.2s;
    z-index: -1;
  }
  &:after {
    content: "";
    position: absolute;
    height: 3px;
    width: calc(100% + 9px);
    bottom: -7px;
    left: -2px;
    background: black;
    transition: 0.2s;
    z-index: -1;
  }

  a {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0.3em 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: ${props => props.theme.colors.white};
    font-weight: 100;
    transition: 0.2s;
    @media ${device.tablet} {
      max-width: 250px;
    }
    @media ${device.laptop} {
      width: 100%;
    }
  }
  &:hover {
    background-color: ${props => props.theme.colors.black};
    &:before {
      right: calc(100% + 4px);
      top: -7px;
      height: calc(100% + 10px);
    }
    &:after {
      width: calc(100% + 10px);
      bottom: calc(100% + 4px);
      left: -7px;
    }
    & > a {
      text-decoration: underline;
    }
  }
  ${({ loadMore }) =>
    loadMore &&
    css`
      width: 80%;
      @media ${device.laptop} {
        width: 100%;
        max-width: 300px;
      }
    `}
  ${({ menu }) =>
    menu &&
    css`
      width: 45%;
      @media ${device.laptop} {
        width: 55%;
        max-width: 200px;
      }
      &:nth-last-of-type(1) {
        background-color: ${props => props.theme.colors.accent};
      }
    `}
`
