import styled, { css } from "styled-components"
import { device } from "../../utils/device"

export const StyledImage = styled.img`
  display: block;
  width: 100%;

  ${({ blogSection }) =>
    blogSection &&
    css`
      display: block;
      background: grey;
      height: 200px;
      width: 100%;
      object-fit: cover;
      opacity: 0.5;
      transition: 0.3s;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      @media ${device.laptop} {
        max-width: 100%;
      }
    `}
`
