import styled, { css } from "styled-components"

export const StyledContactFloater = styled.article`
  @media (max-width: 1024px) {
    opacity: 0;
  }
  position: fixed;
  bottom: 0;
  left: 50px;
  z-index: 20;
  height: auto;
  min-height: 175px;
  min-width: 5px;
  width: auto;
  .contact-floater__contact-icons {
    position: absolute;
    top: calc(-100% + 50px);
    left: 0;
    transform: translate(-50%, 50%);
    a {
      margin: 0;
      padding: 0 0;
      img {
        margin: 5px;
      }
    }
  }
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 175px;
    background: ${props => props.theme.colors.gray};
  }
  ${({ right }) =>
    right &&
    css`
      left: calc(100% - 50px);
      a {
        color: ${props => props.theme.colors.gray};
        font-family: ${props => props.theme.fontFamily.fixedContact};
        font-size: ${props => props.theme.fontSize.xs};
        display: block;
        position: absolute;
        left: calc(100% + 5px);
        top: -100%;
        transform: rotate(90deg);
        transform-origin: top left;
      }
    `}
`
