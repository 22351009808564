import styled, { css } from "styled-components"
import { device } from "../../utils/device"

export const StyledParagraph = styled.p`
  font-family: ${props => props.theme.fontFamily.paragraph};
  font-size: ${props => props.theme.fontSize.s};
  color: ${props => props.theme.colors.black};
  line-height: 24px;
  @media ${device.laptop} {
    font-size: ${props => props.theme.fontSize.m};
  }
  ${({ blogSection }) =>
    blogSection &&
    css`
      max-width: 85%;
      margin: 1em 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
`
