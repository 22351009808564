import gsap from "gsap"
import { theme } from "../../utils/theme"

export const preloaderAnimation = async () => {
  const main = document.querySelector("main")
  const preloader = document.querySelector("#preloader")
  const preloaderLogo = document.querySelector(".preloader__logo")

  const preloaderTimeLine = gsap.timeline({})
  await gsap.set(main, {
    display: "none",
  })
  await gsap.set(preloaderLogo, {
    boxShadow: "inset 0 0 5px black",
    borderRadius: "50%",
  })

  await preloaderTimeLine
    .add("start")
    .to(preloaderLogo, {
      scale: 1.1,
      boxShadow: `inset 0 0 35px ${theme.colors.black}`,
      duration: 0.5,
      easing: "circ",
      yoyo: true,
      repeat: 3,
    })
    .to(preloaderLogo, {
      scale: 0.7,
      duration: 0.2,
    })
    .to(preloaderLogo, {
      scale: 3,
      opacity: 0,
      duration: 0.5,
    })
    .fromTo(
      preloader,
      { display: "block" },
      { display: "none", duration: 0.01 }
    )
    .to(main, { display: "block", duration: 0.01 })
}
