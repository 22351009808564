import styled from "styled-components"

export const StyledToolCard = styled.figure`
  width: 175px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 5px;
  box-shadow: 0px 0px 25px ${props => props.theme.colors.black};
  @media (min-width: 1024px) {
    width: 252px;
    height: 127px;
  }
  .tool-card-image-container {
    max-width: 50px;
    max-height: 80px;
    @media (min-width: 1024px) {
      max-width: 100px;
      max-height: 80px;
    }
  }
  .tool-card-image {
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 80px;
  }
`
