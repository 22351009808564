import React from "react"
import Button from "./Button"
import { StyledMenu } from "./styled-components/Menu.style"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const Menu = ({ isVisible }) => {
  const buttons = useStaticQuery(graphql`
    query navButtonQuery {
      allDatoCmsNavbutton(sort: { fields: navbuttonId, order: ASC }) {
        edges {
          node {
            navbuttonId
            navbuttonLink
            navbuttonTxtEn
            navbuttonTxtPl
          }
        }
      }
    }
  `).allDatoCmsNavbutton.edges

  const navButton = buttons.map(({ node }, index) => (
    <Button
      className={`button-hover ${
        index === 3 ? "button-hover__accent-color" : ""
      }`}
      blank={index === 3}
      menu
      key={node.navbuttonId}
      buttonText={node.navbuttonTxtPl}
      link={
        !String(node.navbuttonLink).includes("http") ? node.navbuttonLink : null
      }
      extLink={
        String(node.navbuttonLink).includes("http") ? node.navbuttonLink : null
      }
    />
  ))
  return (
    <StyledMenu className="menu menu-inner" isVisible={isVisible}>
      {navButton}
    </StyledMenu>
  )
}

export default Menu

Menu.propTypes = {
  isVisible: PropTypes.bool,
  button: PropTypes.string,
}
